import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/ArrowOutward.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/CardMembershipOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/Check.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/ExpandMore.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/Instagram.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/KeyboardArrowRight.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/LinkedIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/LocalHospitalOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/LocationOnOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/LocationSearching.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/Phone.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/Search.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/ShoppingCart.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/ShoppingCartOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/VerifiedUserOutlined.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Accordion/Accordion.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionDetails/AccordionDetails.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionSummary/AccordionSummary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Divider/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/List/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/ListItem/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/effect-fade.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/a11y.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Banners/TopBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Footer/FacebookIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Footer/FooterLanguageChoose.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainBlogTab/MainBlogTab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainBottomCarousel/MainBottomCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainCarousel/MainCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainFAQTab/MainFaqBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainFAQTab/MainFAQTab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainPageCategoryBox/MainPageCategoryBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainProductsTab/MainProductsTab.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NewsletterBox/NewsletterBox.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/swiper.scss");
