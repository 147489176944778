import { FC } from "react";

import Image from "next/image";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { colorPrimary700, colorText600, colorText800 } from "theme/colors";
import { DotIcon } from "theme/SvgIcons";

type ArticleMainBoxPropsType = {
  title: string;
  image: string;
  url: string;
  categoryName: string;
  categoryHref: string;
  date: string;
};

const ArticleMainBox: FC<ArticleMainBoxPropsType> = ({
  title,
  image,
  url,
  categoryName,
  categoryHref,
  date,
}) => {
  return (
    <>
      <Link href={url} aria-label={title}>
        <Box
          minHeight="250px"
          sx={{ mb: 5, height: "250px", position: "relative" }}
        >
          <Image
            alt={title}
            src={image}
            fill
            style={{ objectFit: "cover" }}
            sizes="(max-width: 760px) 150vw, (max-width: 960px) 100vw, (max-width: 1400px) 50vw, 33vw"
          />
        </Box>
      </Link>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Link href={categoryHref} color={colorPrimary700} variant="body2">
          {categoryName}
        </Link>
        <DotIcon />
        <Typography color={colorText600} variant="body2">
          {date}
        </Typography>
      </Stack>
      <Link href={url}>
        <Typography
          variant="subtitle1"
          component="h4"
          fontWeight={600}
          sx={{ color: colorText800 }}
        >
          {title}
        </Typography>
      </Link>
    </>
  );
};

ArticleMainBox.propTypes = {};

export default ArticleMainBox;
